import { createHashRouter } from 'react-router-dom'
import Charge from '@/pages/charge'
import Bill from '@/pages/bill'
import BillDetail from '@/pages/billDetail'
const router = createHashRouter([
    //默认路由
    {
        index:true,
        element: <Charge/>
    },
    {
        path:'/charge',
        element: <Charge/>
    },
    {
        path:'/bill',
        element: <Bill/>
    },
    {
        path:'/billDetail',
        element: <BillDetail/>
    }
])
export default router