import { useNavigate,useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getAction } from "@/utils/request.js"
import './index.scss'
const BillDetail = () => {
    let token = 'iOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2Vy'
    const location = useLocation()
    const navigate = useNavigate()
    const { state } = location
    
    const [billinfo,setBillinfo] = useState({})
    useEffect(()=> {
        getBillDetail(state)
    },[])
    const getBillDetail = (state)=> {
        getAction('hikvision_charg/charging_bill',{bill_id: state.bill_id,token:token}).then(res=>{
            if(res && res.val && res.val.length){
                setBillinfo(res.val[0])
            }
        })
    }
    const back = () => {
        navigate(-1)
    }
    const [ visible, setVisible] = useState(false)
    const [ errorvisible, setErrorvisible] = useState(false)
    const [ errorMsg, setErrorMsg] = useState('')
    const stopClick = () => {
        getAction('hikvision_charg/stop_charging',{bill_id: state.bill_id,token:token}).then(res=>{
            if(res && res.ret ===0){
                getBillDetail(state)
                setVisible(true)
                setTimeout(()=>{
                    setVisible(false)
                },2000)
            }else{
                setErrorvisible(true)
                setErrorMsg('停止充电失败')
                setTimeout(()=>{
                    setErrorvisible(false)
                },2000)
            }
        })
    }
    return (
        <div className='billdetail'>
            <div className='backbutton' onClick={back}>返回</div>
            <div className='billdetail-list'>
                <div className='detai-item'>
                    <div className='item-name'>订单流水号</div>
                    <div className='item-value'>{billinfo.bill_id}</div>
                </div>
                <div className='detai-item'>
                    <div className='item-name'>充电设备ID</div>
                    <div className='item-value'>{billinfo.dev_id}</div>
                </div>
                <div className='detai-item'>
                    <div className='item-name'>充电通道</div>
                    <div className='item-value'>{ Number(billinfo.dev_port) + 1}</div>
                </div>
                <div className='detai-item'>
                    <div className='item-name'>开始时间</div>
                    <div className='item-value'>{billinfo.start_time}</div>
                </div>
                <div className='detai-item'>
                    <div className='item-name'>结束时间</div>
                    <div className='item-value'>{billinfo.end_time}</div>
                </div>
                <div className='detai-item'>
                    <div className='item-name'>费用</div>
                    <div className='item-value'>{billinfo.chg_cost}</div>
                </div>
                <div className='detai-item'>
                    <div className='item-name'>充电状态</div>
                    <div className='item-value'>{billinfo.chg_status == 1?'充电完成':'充电中'}</div>
                </div>
            </div>
            {
                billinfo.chg_status != 1 &&
                <div className='stopbutton' onClick={stopClick}>停止充电</div>
            }
            {
                visible && <div className="dialog">
                    <div className="popup-content">已停止充电</div>
                </div>
            }
            {
                errorvisible && <div className="dialog">
                    <div className="popup-content">{errorMsg}</div>
                </div>
            }
        </div>
    )
}
export default BillDetail