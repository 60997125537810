import { useState,useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getAction } from "@/utils/request.js"
import zhandian from '@/assets/zhandian.png'
import chongdianzhuang from '@/assets/chongdianzhuang.png'
import chongdianliang from '@/assets/chongdianliang.png'
import ordertime from '@/assets/ordertime.png'
import './index.scss'
const Bill = ()=>{
    const token = "iOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2Vy"
    let user = ''
    const navigate = useNavigate()
    const getPageInfo = () =>{
        let urlString = window.location.href
        let paramsString = urlString.split('?')
        if(paramsString.length>0){
            let paramsStringEnding = new URLSearchParams('?' + paramsString[1])
            user = paramsStringEnding.get('user')
        }
    }
    getPageInfo()
    // const backButton = () => {
    //     window.open("","_self").close()
    // }
    const [billList,setBillList] = useState([])
    const getOrderList = () => {
        getAction('hikvision_charg/charging_bill',{user:user,token:token}).then(res=>{
            if(res && res.val && res.val.length){
                setBillList(res.val)
            }
        })
    }
    useEffect(() => {
        getOrderList()
    },[])
    const stopBill = (item)=> {
        navigate('/billDetail',{ state: item })
    }
    return (
        <div className="order">
            <div className="order-header">
                {/* <div className="back" onClick={backButton}>返回</div> */}
                <span>交易明细</span>
            </div>
            <div className="order-list">
                {
                    billList.map((item,index) => 
                        <div className="order" key={index} onClick={()=>stopBill(item)}>
                            {/* <div className="order-info">
                                <img src={zhandian} alt="" className="order-info-icon"/>
                                <div className="order-info-desc">充电点：</div>
                                <div className="order-info-value">{item.dev_name}</div>
                            </div> */}
                            <div className="order-info">
                                <img src={chongdianzhuang} alt="" className="order-info-icon"/>
                                <div className="order-info-desc">电桩号：</div>
                                <div className="order-info-value">{item.dev_id}</div>
                            </div>
                            <div className="order-info">
                                <img src={chongdianliang} alt="" className="order-info-icon"/>
                                <div className="order-info-desc">充电状态：</div>
                                <div className="order-info-value">{item.chg_status == 1?'充电完成':'充电中'}</div>
                            </div>
                            {
                                item.chg_status == 1 ?
                                (<div className="order-info">
                                    <img src={ordertime} alt="" className="order-info-icon"/>
                                    <div className="order-info-desc">充电时长：</div>
                                    <div className="order-info-value">{item.charging_time}</div>
                                </div>)
                                :
                                (<div className="order-info">
                                    <img src={ordertime} alt="" className="order-info-icon"/>
                                    <div className="order-info-desc">充电开始时间：</div>
                                    <div className="order-info-value">{item.start_time}</div>
                                </div>)
                            }
                            { item.chg_status == 1 && <div className="order-divider"></div> }
                            { item.chg_status == 1 && 
                                <div className="order-detail"> 
                                    <span>{item.start_time}</span>
                                    <div>消费金额 <span class="order-cost">{item.chg_cost}</span></div>
                                </div>
                            }
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}
export default Bill