import axios from 'axios'
const request = axios.create({
  baseURL: 'https://ddz.gangyunchong.com/',
  timeout: 120000
})
// request.interceptors.request.use(config => {
//   // const token = window.localStorage.getItem(ACCESS_TOKEN)
//   // if (token) {
//   //   config.headers['X-Access-Token'] = token
//   // }
//   return config
// }, (error) => {
//   return Promise.reject(error)
// })

request.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  console.log(error)
  try{

  }
  catch(error){

  }
  switch (error.response && error.response.status) {
    case 403:
      break
    case 500:
      break
    case 404:
      break
    case 504:
      break
    case 401:
      break
    default:
      break
  }
  // return Promise.reject(error)
})
export default request
//post
export function postAction(url, parameter) {
  return request({
    url: url,
    method: 'post',
    data: parameter
  })
}

//put
export function putAction(url, parameter) {
  return request({
    url: url,
    method: 'put',
    data: parameter
  })
}

//post method= {post | put}
export function httpAction(url, parameter, method) {
  return request({
    url: url,
    method: method,
    data: parameter
  })
}

//get
export function getAction(url, parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}

//deleteAction
export function deleteAction(url, parameter) {
  return request({
    url: url,
    method: 'delete',
    params: parameter
  })
}