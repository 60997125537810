import { useState,useEffect } from 'react'
import './index.scss'
import classNames from 'classnames'
import {getAction, postAction} from "@/utils/request.js"
import zhanyong from '@/assets/zhanyong.png'
import kongxian from '@/assets/kongxian.png'
import guzhang from '@/assets/guzhang.png'
const Charge = ()=>{
    let token = "iOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2Vy"
    let dev_id = ''
    let user = ''
    const getPageInfo = () =>{
        let urlString = window.location.href
        let paramsString = urlString.split('?')
        if(paramsString.length>0){
            let paramsStringEnding = new URLSearchParams('?' + paramsString[1])
            dev_id = paramsStringEnding.get('dev_id')
            user = paramsStringEnding.get('user')
        }
    }
    getPageInfo()
    const [ packageList, setPackageList] = useState([])
    const [info,setInfo]  = useState([])
    const getPackageList = () => {
        getAction('hikvision_charg/charging_package',{dev_id:dev_id,token:token}).then(res=>{
            if(res.val && res.val.length){
                setPackageList(res.val)
            }
            if(res.info && res.info.length){
                setInfo(res.info)
            }
        })
    }
    const [formData, setFormData] = useState({package:1,portNumber:''})
    const packageClick = (item) => {
        setFormData({
            ...formData,
            package:item.id
        })
    }
    const [channelStatus,setChannelStatus] = useState([])
    const [status, setStatus] = useState('off')
    const getPortList = () => {
        getAction('gyc/dev_status',{dev_id:dev_id,token:token}).then(res=>{
            if(res && res.val){
                setChannelStatus(res.val.channelStatus)
                setStatus(res.val.status)
            }
        })
    }
    
    useEffect(() => {
        getPackageList()
        getPortList()
    },[])
    const portClick = (index)=>{
        if(channelStatus[index] == 1 || channelStatus[index] == 2){
            return
        }
        setFormData({
            ...formData,
            portNumber:index
        })
    }
    const [ visible, setVisible] = useState(false)
    const [ errorvisible, setErrorvisible] = useState(false)
    const [ errorMsg, setErrorMsg] = useState('')
    const submit = () => {
        if(status === 'off'){
            setErrorvisible(true)
            setErrorMsg('设备不在线')
            setTimeout(()=>{
                setErrorvisible(false)
            },2000)
            return 
        }
        if(dev_id && formData.package && (formData.portNumber || formData.portNumber ===0 ) && user && status === 'on'){
            postAction('hikvision_charg/start_charging?token='+token,{
                dev_id: dev_id,
                dev_port: formData.portNumber,
                user: user,
                charging_package_id: formData.package,
            }).then(res=>{
                if(res.ret === 0){
                    setVisible(true)
                    setTimeout(()=>{
                        setVisible(false)
                    },2000)
                }else{
                    setErrorvisible(true)
                    setErrorMsg(res.err|| '充电失败')
                    setTimeout(()=>{
                        setErrorvisible(false)
                    },2000)
                }
            })
        }
    }
    return (
        <div className="home">
            <div className="home-package-title">套餐选择</div>
            <div className="home-package-list" >
                {
                    packageList.map(item => 
                        (
                        <div className={classNames(['home-package',{'home-package-active':formData.package == item.id}])} onClick={() =>packageClick(item)}  key={item.id}>
                            {
                                (item.charging_time === 0 && item.money === 0)?
                                <div className="package-price">充满自停</div>:
                                <div className="package-price">{item.money}元</div>
                            }
                            <div className="package-selected"> √ </div>
                        </div>
                        )
                    )
                }
                
            </div>
            <div className="home-package-title">端口选择</div>
            {
                Array.isArray(channelStatus) && 
                <div className="home-port-list" v-if="">
                    {
                        channelStatus.map((item,index) => 
                            (
                                <div className={classNames(['home-port',{'kongxian': item == 0},{'zhanyong': item == 1},{'guzhang':item == 2},{'home-port-selected':index === formData.portNumber}])} key={index} onClick={() => portClick(index)}>
                                    <div className="home-port-number">NO.{index+1}</div>
                                    <div className="home-port-icon">
                                        { item == 1 && <img src={zhanyong} alt='' style={{width: '20px',height:'20px'}}/> }
                                        { item == 0 && <img src={kongxian} alt='' style={{width: '20px',height:'20px'}}/> }
                                        { item == 2 && <img src={guzhang} alt='' style={{width: '20px',height:'20px'}}/> }
                                    </div>
                                    <div className="home-port-status">{item === 1?'占用':item == 2?'故障':'空闲'}</div>
                                    { index == formData.portNumber && <div className="port-selected"> √ </div> }
                                </div>
                            )
                        )
                    }
                </div>
            }
            <div className="home-tips">
                收费说明：
                {
                    info.map((item,index) => 
                        (
                            <div key={index}>{item}</div>
                        )
                    )
                }
            </div>
            <div className="home-button" onClick={submit}>立即使用</div>
            {
                visible && <div className="dialog">
                    <div className="popup-content">已开始充电</div>
                </div>
            }
            {
                errorvisible && <div className="dialog">
                    <div className="popup-content">{errorMsg}</div>
                </div>
            }
            
        </div>
    )
}
export default Charge